<template>
    <div>
        <p>
            You are cleared to work providing you remain asymptomatic <b>AND</b> practice these guidelines.
        </p>
        <ul>
            <li>
                Self-monitor temperature and assess symptoms daily for 14 days from date of arrival into Alaska.
                Daily temperature check and symptom assessment should be performed prior to start of work
            </li>
            <li>
                Maintain 6 feet distancing as work duties permit.
            </li>
            <li>
                If your work duties require closer contact (&lt; 6 feet), in addition to a face mask,
                wear eye protection (eye goggles or face shield) and gloves for patient care.
            </li>
            <li>
                Minimize close contacts (within 6 ft).
            </li>
            <li>
                Practice excellent hand and respiratory hygiene.
            </li>
            <li>
                Disinfect and clean work spaces regularly.
            </li>
            <li>
                Physically distance when taking breaks. Be 6 ft apart when dropping face mask to eat or drink.
            </li>
            <li>
                Please send travel COVID test result(s) to Employee Health when available.
            </li>
            <li>
                During non-work periods, you are expected to follow current Alaska Health Mandate travel guidelines.
                Contact Employee Health (714-4000) if you have any questions regarding these guidelines.
            </li>
        </ul>
        <b>
            <ul class="list-inline">
                <li>
                    If you develop a fever (>100.0 F) and/or one or more of the following symptoms (cough, shortness of breath, congestion, chills,
                    diminished sense of taste or smell, diarrhea, nausea, vomiting, fatigue, headache, muscle/joint aches, runny nose, sore throat)
                    you must contact Employee Health immediately at 714-4000 (even mild symptoms).
                </li>
                <li>
                    Do not report to work sick.
                </li>
                <li>
                    If you become sick during the workday, isolate yourself and contact your department leadership
                    and Employee Health (714-4000) immediately. Prepare to be sent home.Surfaces in your workspace
                    should be cleaned and disinfected.
                </li>
                <li>
                    Wear a face mask for source control at all times.
                </li>
                <li>
                    If you have completed post travel COVID—19 testing, if you result positive, isolate and quarantine
                    yourself and contact Employee Health (714-4000) immediately.
                </li>
            </ul>
        </b>
        <b-button @click="launchSig"
                  variant="success">
            Submit
        </b-button>

    </div>
</template>

<script>

    export default {
        name: "post_travel_guidelines",
        data(){
            return{
                signature: false,
                next_page: false,
                signature_data: "",
            }
        },
        methods: {
            launchSig: function (){
                this.$emit('openSig')
            }
        }
    }
</script>

    <style scoped>
    ul {
        list-style-type: disc !important;
        padding-left: 1em;
    }
    </style>