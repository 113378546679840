<template>
    <div class="p-3">
        <b-row>
            <b-col cols="12">
                <b-form>
                    <b-form-row>
                        <b-col cols="12">
                            <b-form-group
                                id="face-mask"
                                >
                               <Signature></Signature>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                id="agreed_page1"
                                >
                                 <b-form-checkbox
                                     id="agreed_page1_box"
                                     v-model="agreed"
                                     name="agreed_page1"
                                    >
                                     <p v-if="setPage === 1">
                                         I do hereby attest that this information is true, accurate and
                                         complete to the best of my knowledge and I understand that any
                                         falsification or omission may be subject to corrective action.
                                     </p>
                                     <p v-else>
                                         I do hereby attest that I understand this information and agree to comply with these guidelines.
                                         <br/>
                                         <br/>
                                         I understand that any time required to be in quarantine due to personal travel will be paid under PTO/IAP.
                                     </p>
                                    </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols="12">
                            <VueSignaturePad id="signature"
                                             width="100%"
                                             height="300px"
                                             ref="signaturePad"
                                             :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                        </b-col>
                    </b-form-row>
                    <b-form-row class="text-right mt-3">
                        <b-col cols="12">
                            <b-button variant="danger"
                                      class="mr-2"
                                      @click="clearSig">
                                Cancel
                            </b-button>
                            <b-button variant="success"
                                      @click="save"
                                      :disabled=" !agreed || $refs.signaturePad.isEmpty()">
                                Submit
                            </b-button>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import Signature from "./sig-holder"
    export default {
        name: "signature-modal",
        components: {
            Signature
        },
        props: {
            currentPage: {
                type: Number
            }
        },
        data(){
            return{
                agreed: false,
                pen_input: "",
                setPage: 0
            }
        },
        created() {
            this.setPage = this.currentPage
        },
        methods: {
            clearSig() {
                this.$refs.signaturePad.clearSignature();
                this.$emit('closeSigPad')
            },
            save() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty){
                    this.$emit("signatureSubmit", data)
                }

            }
        },

    }
</script>

<style>
#signature {
    border: solid 2px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000, #000);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
</style>