<template>
    <div>
        <b-form>
            <b-form-row>
                <b-col cols="12">
                    <b-form-group
                        id="submittedForSomeoneElse"
                    >
                        <b-form-checkbox
                            id="submittedForSomeoneElse-input"
                            v-model="submittedForSomeoneElse"
                            name="submittedForSomeoneElse-input"
                        >
                            Are you submitting this form for someone else?
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row v-if="submittedForSomeoneElse">
                <b-col cols="12" md="6">
                    <b-form-group id="submitte_first_name"
                                  label="Your First Name:"
                                  label-for="submitte_first_name_input">
                        <b-form-input id="submitte_first_name_input"
                                      v-model="form.submitte_first_name"
                                      placeholder="Enter first name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="submitte_last_name"
                                  label="Your Last Name:"
                                  label-for="submitte_last_name_input">
                        <b-form-input id="submitte_last_name_input"
                                      v-model="form.submitte_last_name"
                                      placeholder="Enter last name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_first_name"
                                  label="Employee's First Name:"
                                  label-for="employee_first_name_input">
                        <b-form-input id="employee_first_name_input"
                                      v-model="form.employee_first_name"
                                      placeholder="Enter employee first name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_last_name"
                                  label="Employee's Last Name:"
                                  label-for="employee_last_name_input">
                        <b-form-input id="employee_last_name_input"
                                      v-model="form.employee_last_name"
                                      placeholder="Enter employee last name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="employee_email"
                        label="Employees Email:"
                        label-for="employee_email_input"
                    >
                        <b-form-input
                            :class="{error: !valid_email}"
                            id="employee_email_input"
                            v-model="form.email"
                            type="email"
                            placeholder="Enter email"
                            required
                        ></b-form-input>
                        <div class="text-danger" v-if="!valid_email">
                            <small>Email not valid</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_phone_number"
                                  label="Employee Phone Number:"
                                  label-for="employee_phone_number_input">
                        <b-form-input
                            id="employee_phone_number_input"
                            v-model="form.phone_number"
                            placeholder="Enter phone number"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_department"
                                  label="Employee Department:"
                                  label-for="employee_department_input">
                        <b-form-input
                            id="employee_department_input"
                            v-model="form.department"
                            placeholder="Enter department"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_position"
                                  label="Employee Position:"
                                  label-for="employee_position_input">
                        <b-form-input id="employee_position_input"
                                      v-model="form.position"
                                      placeholder="Enter position"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row v-else>
                <b-col cols="12" md="6">
                    <b-form-group id="first_name"
                                  label="Your First Name:"
                                  label-for="employee_first_name_input">
                        <b-form-input id="employee_first_name_input"
                                      v-model="form.employee_first_name"
                                      placeholder="Enter first name name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="last_name"
                                  label="Your Last Name:"
                                  label-for="employee_last_name_input">
                        <b-form-input id="employee_last_name_input"
                                      v-model="form.employee_last_name"
                                      placeholder="Enter last name name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="employee_email"
                        label="Your Email:"
                        label-for="employee_email_input"
                    >
                        <b-form-input
                            :class="{error: !valid_email}"
                            id="employee_email_input"
                            v-model="form.email"
                            type="email"
                            placeholder="Enter email"
                            required
                        ></b-form-input>
                        <div class="text-danger" v-if="!valid_email">
                            <small>Email not valid</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_phone_number"
                                  label="Your Phone Number:"
                                  label-for="employee_phone_number_input">
                        <b-form-input
                            id="employee_phone_number_input"
                            v-model="form.phone_number"
                            placeholder="Enter phone number"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_department"
                                  label="Your Department:"
                                  label-for="employee_department_input">
                        <b-form-input
                            id="employee_department_input"
                            v-model="form.department"
                            placeholder="Enter department"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="employee_position"
                                  label="Your Position:"
                                  label-for="employee_position_input">
                        <b-form-input id="employee_position_input"
                                      v-model="form.position"
                                      placeholder="Enter position"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols="12" md="6">
                    <b-form-group id="screening_date"
                                  label="Screening Date:"
                                  label-for="screening_date_input">
                        <b-form-datepicker id="screening_date_input"
                                           v-model="form.screening_date"
                                           class="mb-2">
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group id="arrival_date"
                                  label="Arrival Date:"
                                  label-for="arrival_date_input">
                        <b-form-datepicker id="arrival_date_input"
                                           v-model="form.arrival_date"
                                           class="mb-2">
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>
<!--                <b-col cols="12" md="6" lg="4">-->
<!--                    <b-form-group id="traveled_from"-->
<!--                                  label="Traveled From:"-->
<!--                                  label-for="traveled_from_input">-->
<!--                        <b-form-select v-model="form.traveled_from" :options="options"></b-form-select>-->
<!--                    </b-form-group>-->
<!--                </b-col>-->
            </b-form-row>
            <b-form-row>
                <b-col cols="12" md="6" lg="3">
                    <b-form-group
                        id="face-mask"
                    >
                        <b-form-checkbox
                            id="face-mask-input"
                            v-model="form.face_mask"
                            name="face-mask-input"
                        >
                            Was a face mask worn during travels?
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <b-form-group
                        id="travel-tested"
                    >
                        <b-form-checkbox
                            id="travel-tested-input"
                            v-model="form.travel_tested"
                            name="travel-tested-input"
                        >
                            Has the employee been travel tested for COVID-19?
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <b-form-group
                        id="social-distanced"
                    >
                        <b-form-checkbox
                            id="social-distanced-input"
                            v-model="form.social_distanced"
                            name="social-distanced-input"
                        >
                            Was 6 ft distancing practiced as best as possible during travels?
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <b-form-group
                        id="vaccine"
                    >
                        <b-form-checkbox
                            id="vaccine-input"
                            v-model="form.vaccine"
                            name="vaccine-input"
                        >
                            I have been fully vaccinated with the COVID-19 vaccine.
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="covid-contact"
                    >
                        <b-form-checkbox
                            id="covid-contact-input"
                            v-model="form.covid_contact"
                            name="covid-contact-input"
                        >
                            Was there contact with a person with known COVID-19 or under the evaluation
                            for exposure to COVID-19?
                        </b-form-checkbox>
                    </b-form-group>
                    <small v-if="form.covid_contact" class="text-danger">
                        <b>
                            You must contact Employee Health prior to return to work.
                        </b>
                    </small>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group
                        id="covid-symptoms"
                    >
                        <b-form-checkbox
                            id="covid-symptoms-input"
                            v-model="form.covid_symptoms"
                            name="covid-symptoms-input"
                        >
                            Does the employee have one or more of the following symptoms:
                        </b-form-checkbox><div>
                        <b-row>
                            <b-col cols="6">
                                <ul>
                                    <li>
                                        fever (> 100.0 F)
                                    </li>
                                    <li>
                                        shortness of breath
                                    </li>
                                    <li>
                                        chills
                                    </li>
                                    <li>
                                        diarrhea
                                    </li>
                                    <li>
                                        vomiting
                                    </li>
                                    <li>
                                        headache
                                    </li>
                                    <li>
                                        runny nose
                                    </li>
                                    <li>
                                        sore throat
                                    </li>
                                </ul>
                            </b-col>
                            <b-col cols="6">
                                <ul>
                                    <li>
                                        cough
                                    </li>
                                    <li>
                                        congestion
                                    </li>
                                    <li>
                                        chills
                                    </li>
                                    <li>
                                        nausea
                                    </li>
                                    <li>
                                        fatigue
                                    </li>
                                    <li>
                                        muscle/joint aches
                                    </li>
                                    <li>
                                        new onset diminished sense of taste or smell
                                    </li>
                                </ul>
                            </b-col>
                        </b-row>

                    </div>
                    </b-form-group>
                    <small v-if="form.covid_symptoms" class="text-danger">
                        <b>
                            You must contact Employee Health prior to return to work.
                        </b>
                    </small>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-button @click="validateForm(form)"
                          :disabled="disabled"
                          variant="success">
                    Submit
                </b-button>
            </b-form-row>
        </b-form>
    </div>
</template>

<script>
    export default {
        name: "travel_form",
        components: {
        },
        props: {
            formReset: {
                type: Boolean
            }
        },
        data(){
            return{
                disabled: true,
                valid_email: true,
                submittedForSomeoneElse: false,
                next_page: false,
                signature: false,
                form: {
                    submitte_first_name: "",
                    submitte_last_name: "",
                    employee_first_name: "",
                    employee_last_name: "",
                    department: "",
                    position: "",
                    email: "",
                    phone_number: "",
                    screening_date: "",
                    arrival_date: "",
                    face_mask: false,
                    travel_tested: false,
                    social_distanced: false,
                    covid_contact: false,
                    covid_symptoms: false,
                    vaccine: false,
                    signature_one: "",
                    signature_two: ""
                },
                options: [
                    {
                        value: "",
                        text: 'Please select an option'
                    },
                    {
                        text: "Alabama",
                        value: "AL"
                    },
                    {
                        text: "American Samoa",
                        value: "AS"
                    },
                    {
                        text: "Arizona",
                        value: "AZ"
                    },
                    {
                        text: "Arkansas",
                        value: "AR"
                    },
                    {
                        text: "California",
                        value: "CA"
                    },
                    {
                        text: "Colorado",
                        value: "CO"
                    },
                    {
                        text: "Connecticut",
                        value: "CT"
                    },
                    {
                        text: "Delaware",
                        value: "DE"
                    },
                    {
                        text: "District of Columbia",
                        value: "DC"
                    },
                    {
                        text: "Micronesia",
                        value: "FM"
                    },
                    {
                        text: "Florida",
                        value: "FL"
                    },
                    {
                        text: "Georgia",
                        value: "GA"
                    },
                    {
                        text: "Guam",
                        value: "GU"
                    },
                    {
                        text: "Hawaii",
                        value: "HI"
                    },
                    {
                        text: "Idaho",
                        value: "ID"
                    },
                    {
                        text: "Illinois",
                        value: "IL"
                    },
                    {
                        text: "Indiana",
                        value: "IN"
                    },
                    {
                        text: "Iowa",
                        value: "IA"
                    },
                    {
                        text: "Kansas",
                        value: "KS"
                    },
                    {
                        text: "Kentucky",
                        value: "KY"
                    },
                    {
                        text: "Louisiana",
                        value: "LA"
                    },
                    {
                        text: "Maine",
                        value: "ME"
                    },
                    {
                        text: "Marshall Islands",
                        value: "MH"
                    },
                    {
                        text: "Maryland",
                        value: "MD"
                    },
                    {
                        text: "Massachusetts",
                        value: "MA"
                    },
                    {
                        text: "Michigan",
                        value: "MI"
                    },
                    {
                        text: "Minnesota",
                        value: "MN"
                    },
                    {
                        text: "Mississippi",
                        value: "MS"
                    },
                    {
                        text: "Missouri",
                        value: "MO"
                    },
                    {
                        text: "Montana",
                        value: "MT"
                    },
                    {
                        text: "Nebraska",
                        value: "NE"
                    },
                    {
                        text: "Nevada",
                        value: "NV"
                    },
                    {
                        text: "New Hampshire",
                        value: "NH"
                    },
                    {
                        text: "New Jersey",
                        value: "NJ"
                    },
                    {
                        text: "New Mexico",
                        value: "NM"
                    },
                    {
                        text: "New York",
                        value: "NY"
                    },
                    {
                        text: "North Carolina",
                        value: "NC"
                    },
                    {
                        text: "North Dakota",
                        value: "ND"
                    },
                    {
                        text: "Northern Mariana Islands",
                        value: "MP"
                    },
                    {
                        text: "Ohio",
                        value: "OH"
                    },
                    {
                        text: "Oklahoma",
                        value: "OK"
                    },
                    {
                        text: "Oregon",
                        value: "OR"
                    },
                    {
                        text: "Palau",
                        value: "PW"
                    },
                    {
                        text: "Pennsylvania",
                        value: "PA"
                    },
                    {
                        text: "Puerto Rico",
                        value: "PR"
                    },
                    {
                        text: "Rhode Island",
                        value: "RI"
                    },
                    {
                        text: "South Carolina",
                        value: "SC"
                    },
                    {
                        text: "South Dakota",
                        value: "SD"
                    },
                    {
                        text: "Tennessee",
                        value: "TN"
                    },
                    {
                        text: "Texas",
                        value: "TX"
                    },
                    {
                        text: "Utah",
                        value: "UT"
                    },
                    {
                        text: "Vermont",
                        value: "VT"
                    },
                    {
                        text: "Virgin Islands",
                        value: "VI"
                    },
                    {
                        text: "Virginia",
                        value: "VA"
                    },
                    {
                        text: "Washington",
                        value: "WA"
                    },
                    {
                        text: "West Virginia",
                        value: "WV"
                    },
                    {
                        text: "Wisconsin",
                        value: "WI"
                    },
                    {
                        text: "Wyoming",
                        value: "WY"
                    },
                    {
                        text: "International",
                        value: "International"
                    }
                ],
                reset_form: false
            }
        },
        methods: {
            validateForm: function (form){
                // Validates form

                if (this.validateEmail(form.email)){
                    // Email is valid, opens sig modal
                    this.signature = true
                    this.$emit("setForm", this.form)
                }
                else{
                    // Email isnt valid
                    this.valid_email = false
                }
            },
            validateEmail: function (email){
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
        },
        watch: {
            form: {
                handler (newVal){
                    if (this.submittedForSomeoneElse){
                        if (
                            newVal.submitte_first_name === "" ||
                            newVal.submitte_last_name === "" ||
                            newVal.employee_first_name === "" ||
                            newVal.employee_last_name === "" ||
                            newVal.department === "" ||
                            newVal.position === "" ||
                            newVal.email === "" ||
                            newVal.phone_number === "" ||
                            newVal.screening_date === "" ||
                            newVal.arrival_date === ""
                        ){
                            this.disabled = true
                        }
                        else{
                            this.disabled = false
                        }
                    }
                    else{
                        if (
                            newVal.employee_first_name === "" ||
                            newVal.employee_last_name === "" ||
                            newVal.department === "" ||
                            newVal.position === "" ||
                            newVal.email === "" ||
                            newVal.phone_number === "" ||
                            newVal.screening_date === "" ||
                            newVal.arrival_date === ""
                        ){
                            this.disabled = true
                        }
                        else{
                            this.disabled = false
                        }
                    }
                },
                deep: true
            },
            formReset: function (newVal){
                if (newVal){
                    this.form = {
                        submitte_first_name: "",
                        submitte_last_name: "",
                        employee_first_name: "",
                        employee_last_name: "",
                        department: "",
                        position: "",
                        email: "",
                        phone_number: "",
                        screening_date: "",
                        arrival_date: "",
                        face_mask: false,
                        travel_tested: false,
                        social_distanced: false,
                        covid_contact: false,
                        covid_symptoms: false,
                        signature_one: "",
                        signature_two: ""
                    }
                }
            }
        }
    }
</script>

<style>
.form-control.error{
    border: 1px solid red;
}

</style>