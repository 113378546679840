<template>
    <section class="p-2">
        <div class="pb-2">
            <div v-if="currentPage === 1">
                <div class="container pt-3">
                    <div class="row">
                        <div class="col-sm-11">
                            <h1>CPH COVID Travel Screening</h1>
                            <hr><br><br>
                            <travel-form @setForm="setFormVar"
                                         :form-reset="reset_form"
                            ></travel-form>
                        </div>
                    </div>
                </div>
                <b-modal v-model="signature"
                         centered
                         hide-header
                         hide-footer >
                    <signatureModal @signatureSubmit="setSigOne"
                                    @closeSigPad="signature = false"
                                    :currentPage="1"/>
                </b-modal>
            </div>
            <div v-else>
                <div class="container pt-3">
                    <div class="row">
                        <div class="col-sm-11">
                            <h1>Post Travel Guidelines</h1>
                            <hr><br>
                            <post-travel @openSig="contact_eh = false; signature = true"></post-travel>
                        </div>
                    </div>
                </div>
                <b-modal v-model="signature"
                         centered
                         hide-header
                         hide-footer >
                    <signatureModal @signatureSubmit="setSigTwo"
                                    @closeSigPad="signature = false"
                                    :currentPage="2"/>
                </b-modal>
            </div>
        </div>
        <b-modal v-model="form_complete"
                 size="lg"
                 hide-header
                 hide-footer >
            <div class="p-4">
                <b-row class="text-center">
                    <b-col cols="12">
                        <div v-if="submit_error">
                            <h2 class="text-danger py-2">
                                It seems there was an error with your submission
                            </h2>
                            <h4>
                                {{ error_msg }}
                            </h4>
                            <h4 class="py-2">
                                Please contact:
                            </h4>
                            <p class="h5 mb-2">
                                <b-icon icon="telephone"></b-icon>
                                <a href='tel:907-714-4704'>
                                    (907)714-4704
                                </a>
                            </p>
                            <p class="h5 mb-2">
                                <b-icon icon="envelope"></b-icon>
                                <a :href='"mailto:applicationinfo@cpgh.org?subject=COVID Travel Screening Error "+error_msg'>
                                    applicationinfo@cpgh.org
                                </a>
                            </p>
                        </div>
                        <div v-else>
                            <div v-if="contact_eh">
                                <h2 class="text-danger py-2">
                                    <b>
                                        You must contact employee health before returning to work
                                    </b>
                                </h2>
                                <p class="h5 mb-2">
                                    <b-icon icon="telephone"></b-icon>
                                    <a href='tel:907-714-4000'>
                                        (907)714-4000
                                    </a>
                                </p>
                                <p class="h5 mb-2">
                                    <b-icon icon="envelope"></b-icon>
                                    <a href='mailto:employeehealth@cpgh.org?subject=COVID Travel Screening: You must contact employee health before returning to work'>
                                        employeehealth@cpgh.org
                                    </a>
                                </p>
                            </div>
                            <div v-else>
                                <h2 class="text-success py-2">
                                    Travel Screening has successfully been submitted
                                </h2>
                                <h4 class="py-2">
                                    For any additional questions please contact:
                                </h4>
                                <p class="h5 mb-2">
                                    <b-icon icon="telephone"></b-icon>
                                    <a href='tel:907-714-4000'>
                                        (907)714-4000
                                    </a>
                                </p>
                                <p class="h5 mb-2">
                                    <b-icon icon="envelope"></b-icon>
                                    <a href='mailto:employeehealth@cpgh.org?subject=COVID Travel Screening Questions'>
                                        employeehealth@cpgh.org
                                    </a>
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <b-modal v-model="loading"
                 id="loading-modal"
                 size="lg"
                 centered
                 hide-header no-close-on-backdrop
                 hide-footer >
            <div class="text-center">
                <b-spinner variant="info" style="width: 12rem; height: 12rem;" type="grow"
                           label="Spinning" >

                </b-spinner>
            </div>
        </b-modal>


    </section>
</template>

<script>
    import config from '../../public/config.json'
    import travelForm from "../components/travel_form"
    import postTravel from "../components/post_travel_guidelines"
    import signatureModal from "../components/signature-modal"

    import axios from "axios";
    axios.defaults.headers.common['Content-Type'] = 'application/json'

    export default {
        name: "Home",
        components: {
            travelForm,
            postTravel,
            signatureModal
        },
        data(){
            return{
                loading: false,
                signature: false,
                submit_error: false,
                contact_eh: false,
                form_complete: false,
                completed: false,
                currentPage: 1,
                form: {},
                sig_one: "",
                sig_two: "",
                reset_form: false,
                error_msg: ""
            }
        },
        methods: {
            setFormVar: function (value){
                // Sets form var
                this.submit_error = false
                this.form = value
                this.signature = true
            },
            setSigOne: function (value){
                // Sets first sig
                this.form.signature_one = value

                this.signature = false
                let nextPageNum = this.determinePage(this.form.covid_contact, this.form.covid_symptoms)

                if (nextPageNum === 2){
                    // No covid contact or symptoms
                    this.currentPage = nextPageNum
                    window.scrollTo(0,0);
                }
                else{
                    // End of app, will send form off, sets contact eh true

                    this.loading = true
                    this.contact_eh = true
                    this.POST(this.form)
                }
            },
            determinePage: function (covid_contact, covid_symptoms){
                // Determines next page

                var next_page = 2
                if (covid_contact || covid_symptoms){
                    // If covid contact OR covid symptoms
                    next_page = 3
                }
                return next_page
            },
            setSigTwo: function (value){
                this.form.signature_two = value
                this.signature = false
                this.loading = true
                this.POST(this.form)
            },
            POST: function(params){
                var route = config.route;

                return new Promise((resolve, reject) => {
                    axios.post(route, params)
                        .then(response => {
                            this.reset_form = true
                            this.form_complete = true
                            this.loading = false
                            this.resetValues()
                            window.scrollTo(0,0);
                            resolve(response.data);
                        })
                        .catch(err => {
                            // console.log(err)
                            // console.log(err.response)
                            // console.log(err.request)
                            //
                            // this.error_msg = err.response.status +": "+ err.response.statusText
                            this.submit_error = true
                            this.form_complete = true
                            this.loading = false
                            reject(err);
                        });
                });
            },
            resetValues: function (){
                var that = this;
                setTimeout(function() {
                    that.reset_form = false
                    that.form = {}
                    that.currentPage = 1
                }, 500);

            }
        }
    }
</script>

<style >
#loading-modal .modal-content{
    background-color: transparent!important;
    border: none;
}
</style>
